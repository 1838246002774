const jQuery = require('jquery');
window.$ = window.jQuery = jQuery;



$('.js-hamburger').click(function () {
    $('.main-nav').addClass('main-nav--active');
});

$('.close-nav').click(function () {
    $('.main-nav').removeClass('main-nav--active');
});
$('.open-overlay').click(function () {
    $('.overlay').slideDown();
});
$('.close-overlay').click(function () {
    $('.overlay').slideUp();
});




